/*
    This page will be used to display the matches that the user has saved.
    Matches stored in the Firestore database, collection name: "Matches"
    The page will load list of matches from collection and display them in a list, sort by createTime (unix timestamp) (desc)
    Clicking on a match will take the user to the match details page.
    We will have a button to add a new match to the list.
    When click on new match button, the slidepanel will open with a form to add a new match, with the following fields:
    - Opponent's name
    - Date and month (date picker)
    The data will be saved to Firestore in the "Matches" collection, and redirect to the match details page.
*/

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../firebaseConfig";
import {
    collection,
    addDoc,
    getDocs,
    query,
    orderBy,
    deleteDoc,
    doc,
} from "firebase/firestore";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
    Grid,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useMediaQuery
} from "@mui/material";

import Layout from "../theme/layout";
import Icon from "../theme/vectors/bundle";
import SlidePanel from "../theme/partials/slidepanel";
import { TextField } from "../theme/inputs/textfields";

const MatchesPage = () => {
    const [matches, setMatches] = useState([]);
    const [isPanelOpen, setIsPanelOpen] = useState(false);
    const [opponentName, setOpponentName] = useState("");
    const [matchDate, setMatchDate] = useState(new Date());
    const [matchType, setMatchType] = useState("9 balls");

    const [openDialog, setOpenDialog] = useState(false);
    const [matchToDelete, setMatchToDelete] = useState(null);

    const navigate = useNavigate();
    const isMobile = useMediaQuery("(max-width:767px)");

    useEffect(() => {
        fetchMatches();
    }, []);

    const fetchMatches = async () => {
        try {
            const matchesCollection = collection(db, "Matches");
            const q = query(matchesCollection, orderBy("createTime", "desc"));
            const querySnapshot = await getDocs(q);
            const matchesList = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setMatches(matchesList);
        } catch (error) {
            console.error("Error fetching matches: ", error);
        }
    };

    const calculateMatchScore = (racks) => {
        if (!racks || racks.length === 0) return { wins: 0, losses: 0 };
        const wins = racks.filter((rack) => rack.rackResult === "Win").length;
        const losses = racks.filter(
            (rack) => rack.rackResult === "Lose"
        ).length;
        return { wins, losses };
    };

    const handleAddMatch = async () => {
        try {
            const newMatch = {
                creator: "anhnq.mko@gmail.com", // Hardcoded for now
                opponentName,
                matchDate: matchDate.getTime(),
                createTime: Date.now(),
                matchType,
                racks: []
            };
            const docRef = await addDoc(collection(db, "Matches"), newMatch);
            console.log("New match added with ID: ", docRef.id);
            setIsPanelOpen(false);
            fetchMatches();
            navigate(`/match/${docRef.id}`); // Redirect to match details page
        } catch (error) {
            console.error("Error adding new match: ", error);
        }
    };

    const handleDeleteMatch = async () => {
        if (matchToDelete) {
            try {
                await deleteDoc(doc(db, "Matches", matchToDelete));
                console.log("Match deleted successfully");
                fetchMatches(); // Refresh the list after deletion
            } catch (error) {
                console.error("Error deleting match: ", error);
            }
        }
        setOpenDialog(false);
        setMatchToDelete(null);
    };

    const handleOpenDialog = (matchId) => {
        setMatchToDelete(matchId);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setMatchToDelete(null);
    };

    return (
        <Layout>
            <div className="matches-page">
                <h1>Matches</h1>

                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Icon name="addBold" />}
                    onClick={() => setIsPanelOpen(true)}
                >
                    {" "}
                    New Match
                </Button>

                <TableContainer component={Paper} style={{ marginTop: "20px" }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    style={{
                                        width: "50px",
                                        textAlign: "center",
                                    }}
                                >
                                    #
                                </TableCell>
                                <TableCell>Match</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {matches.map((match, index) => (
                                <TableRow
                                    key={match.id}
                                    onClick={() =>
                                        navigate(`/match/${match.id}`)
                                    }
                                    style={{ cursor: "pointer" }}
                                    hover
                                >
                                    <TableCell
                                        style={{
                                            width: "50px",
                                            textAlign: "center",
                                        }}
                                    >
                                        {index + 1}
                                    </TableCell>

                                    <TableCell>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                width: "100%",
                                            }}
                                        >
                                            <span>
                                                <h4
                                                    style={{
                                                        marginBottom: "0.75em",
                                                    }}
                                                >
                                                    {match.opponentName}
                                                </h4>

                                                <p style={{ margin: "0" }}>
                                                    <em>
                                                        {new Date(
                                                            match.matchDate
                                                        ).toLocaleDateString(
                                                            "en-GB",
                                                            {
                                                                day: "2-digit",
                                                                month: "2-digit",
                                                                year: "numeric",
                                                            }
                                                        )}{" "}
                                                        {!isMobile && ` - ${match.matchType}`}
                                                    </em>

                                                    &nbsp;
                                                    &nbsp;

                                                    <Chip
                                                        label={(() => {
                                                            const score =
                                                                calculateMatchScore(
                                                                    match.racks
                                                                );
                                                            if (
                                                                score.wins >
                                                                score.losses
                                                            )
                                                                return (isMobile) ? "W" : "Win";
                                                            if (
                                                                score.wins <
                                                                score.losses
                                                            )
                                                                return (isMobile) ? "L" : "Lose";
                                                            return (isMobile) ? "D" : "Draw";
                                                        })()}
                                                        color={(() => {
                                                            const score =
                                                                calculateMatchScore(
                                                                    match.racks
                                                                );
                                                            if (
                                                                score.wins >
                                                                score.losses
                                                            )
                                                                return "success";
                                                            if (
                                                                score.wins <
                                                                score.losses
                                                            )
                                                                return "error";
                                                            return "default";
                                                        })()}
                                                        size="small"
                                                    />
                                                    
                                                    &nbsp;
                                                    &nbsp;

                                                    <em>
                                                        {(() => {
                                                            const score = calculateMatchScore( match.racks );
                                                            return score.wins +" - "+ score.losses;
                                                        })()}
                                                    </em>
                                                    
                                                </p>

                                            </span>
                                            <div>
                                                <IconButton
                                                    aria-label="delete"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleOpenDialog(
                                                            match.id
                                                        );
                                                    }}
                                                >
                                                    <Icon name="removeBold" />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <SlidePanel
                    title={"New Match"}
                    isOpen={isPanelOpen}
                    onClose={() => setIsPanelOpen(false)}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <DatePicker
                                selected={matchDate}
                                onChange={(date) => setMatchDate(date)}
                                dateFormat="MMMM d, yyyy"
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                id="opponentName"
                                label="Enter your opponent:"
                                variant="outlined"
                                value={opponentName}
                                onChange={(e) =>
                                    setOpponentName(e.target.value)
                                }
                                fullWidth
                                margin="normal"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "start",
                                    gap: "10px",
                                }}
                            >
                                <Chip
                                    label="9 balls"
                                    onClick={() => setMatchType("9 balls")}
                                    color={
                                        matchType === "9 balls"
                                            ? "primary"
                                            : "default"
                                    }
                                    clickable
                                />
                                <Chip
                                    label="10 balls"
                                    onClick={() => setMatchType("10 balls")}
                                    color={
                                        matchType === "10 balls"
                                            ? "primary"
                                            : "default"
                                    }
                                    clickable
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleAddMatch}
                            >
                                Create Match
                            </Button>
                        </Grid>
                    </Grid>
                </SlidePanel>

                <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Confirm Deletion"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete this match? This
                            action cannot be undone.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} color="primary">
                            Cancel
                        </Button>
                        <Button
                            onClick={handleDeleteMatch}
                            color="primary"
                            autoFocus
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Layout>
    );
};

export default MatchesPage;
